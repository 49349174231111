<template>
  <div :key="renderKey">
    <!------------ START: FieldWrapper ------------>
    <FieldWrapper :field="field">
      <!------------ START: Filter entries ------------>
      <div v-for="(entry, i) in value" :key="i" class="filter-row mb-5">
        <!------------ START: Entry input ------------>
        <Select
          :default-value="value[i]['field']"
          :field="selectField"
          @input="val => onEntryInput(i, 'field', val)"
          @change="val => onEntryChange(i, val)"
        />

        <Select
          :default-value="value[i]['op']"
          :field="operatorField"
          @input="val => onEntryInput(i, 'op', val)"
          @change="val => onEntryChange(i, val)"
        />

        <InputField
          type="text"
          :default-value="value[i]['value']"
          :field="field"
          @input="val => onEntryInput(i, 'value', val)"
          @change="val => onEntryChange(i, val)"
        />
        <!------------ END: Entry input ------------>
        <!------------ START: Remove entry button ------------>
        <button
          v-if="showRemoveButtons"
          class="btn btn-icon text-hover-primary"
          @click="removeEntry(i)"
        >
          <i class="fal fa-trash" />
        </button>
        <!------------ END: Remove entry button ------------>
      </div>
      <!------------ END: Filter entries ------------>

      <v-divider class="mx-3 my-2" />

      <!------------ START: Add entry button ------------>
      <div
        v-if="showAddButton"
        class="btn btn-link text-muted p-0 mt-4"
        :class="[isDisabled ? 'disabled' : 'cursor-pointer text-hover-primary']"
        @click="addEntry"
      >
        <i class="fal fa-plus" />
        {{ $t("formHelper.arrayAddEntry") }}
      </div>
      <!------------ END: Add entry button ------------>
    </FieldWrapper>
    <!------------ END: FieldWrapper ------------>
  </div>
</template>

<script>
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import {
  base,
  eagerValidation
} from "@/components/Tools/FormHelper/Helper/mixins";
import _ from "lodash";
import InputField from "@/components/Tools/FormHelper/Components/Input.vue";
import Select from "@/components/Tools/FormHelper/Fields/Select.vue";

export default {
  components: { Select, InputField, FieldWrapper },
  mixins: [base, eagerValidation],
  props: {},
  data() {
    return {
      renderKey: 1
    };
  },
  computed: {
    value: {
      get: function () {
        // Return inherited value
        return this.defaultValue;
      },
      set: function (value) {
        // Trigger input event with new value
        this.onInput(value);
      }
    },
    selectField: function () {
      let field = _.clone(this.field);
      field.options = field.options.field;
      return field;
    },
    operatorField: function () {
      let field = _.clone(this.field);
      field.options = field.options.op;
      return field;
    },
    showAddButton: function () {
      let maxLength = this.field.validations?.maxLength;
      return maxLength !== undefined ? this.value.length < maxLength : true;
    },
    showRemoveButtons: function () {
      let minLength = this.field.validations?.minLength;
      return minLength !== undefined ? this.value.length > minLength : true;
    }
  },
  mounted() {
    this.checkLength();
  },
  methods: {
    checkLength() {
      // Get necessary data
      let minLength = this.field.validations?.minLength,
        length = this.value.length,
        value = [...this.value];
      // If no minimum length is set, abort
      if (!minLength) {
        return;
      }
      // Add entries to array until minimum length is reached
      for (length; length < minLength; length++) {
        value.push({});
      }
      // Set new array
      this.value = value;
    },
    addEntry() {
      // Add new entry to array
      let value = [...this.value];
      value.push({});
      this.onChange("", String(value.length - 1));
      this.value = value;
    },
    removeEntry(index) {
      // Remove entry from array
      let value = [...this.value];
      let removedValue = value.splice(index, 1)[0];
      this.onChange(removedValue, String(index), true);
      this.value = value;
      // Increment rendering key to prevent render mistakes
      this.renderKey++;
    },
    onChange(value, path = "", remove = false) {
      // Emit change event
      const changeEventPayload = {
        name: this.field.name,
        value: value,
        valuePath: path,
        remove: remove,
        old: path ? _.get(this.value, path) : _.cloneDeep(this.value)
      };
      this.$emit("change", changeEventPayload);
      // Execute onChange function if set
      if (typeof this.field.onChange === "function") {
        this.field.onChange();
      }
    },
    // Set whole array to trigger value update
    onEntryInput(index, field, value) {
      let copy = [...this.value];

      copy[index][field] = value;
      this.value = copy;
    },
    // Overwrite default on change method
    onEntryChange(index, payload) {
      const changeEventPayload = {
        name: this.field.name,
        value: payload.value,
        valuePath: String(index),
        old: payload.old
      };
      this.$emit("change", changeEventPayload);
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto;
  column-gap: 20px;
  align-items: center;
}
</style>
